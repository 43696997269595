declare global {
	interface Window {
		disableAndSubmit: (target: HTMLElement) => void
	}
}

import DataTable from 'datatables.net-zf'

import 'datatables.net-fixedcolumns-zf'
import 'datatables.net-fixedheader-zf'

jQuery($ => {
	$(document).foundation()

	if ($('*[data-equalizer]').length > 0) {
		$(window).on('resize', function () {
			Foundation.reInit(['Equalizer'])
		})
	}

	if (window.navigator.platform.match(/Win/i)) {
		$('body').addClass('windows')
	}

	$('.menu.vertical.unloaded').removeClass('unloaded')

	$('#mainMenu').on('show.zf.dropdownmenu', function (event, submenu) {
		let background = $('#menuBackground')
		let adding = false
		if (background.length === 0) {
			background = $('<div id="menuBackground"></div>')
			background.appendTo($('body'))
			adding = true
		}

		const topSubmenu = submenu.hasClass('first-sub') ? submenu : submenu.parents('.first-sub')
		let newHeight = Math.max(
			topSubmenu.outerHeight(),
			submenu.outerHeight() + submenu.offset().top - topSubmenu.offset().top
		)

		submenu.parentsUntil('.first-sub', 'ul').each(function () {
			const $this = $(this)
			newHeight = Math.max(newHeight, $this.outerHeight() + $this.offset().top - topSubmenu.offset().top)
		})

		if (adding) {
			background.height(newHeight)
		} else {
			background.animate({ height: newHeight }, 100)
		}

		background.css('top', $('header').outerHeight() - 1)
	})

	$('#mainMenu').on('hide.zf.dropdownmenu', function () {
		if ($(this).find('.is-active').length === 0) {
			$('#menuBackground').remove()
		}
	})

	// The design here has a weird hybrid back button and navbar title;
	// most native apps separate them by centering the title.
	// That said, we simulate it by replacing the text of the back button
	// with the title.
	function sizeMenu() {
		const menu = $('#mainMenu')

		let activeSubmenu = menu.find('.is-active:not(.is-closing)')
		if (activeSubmenu.length === 0) {
			activeSubmenu = menu
		}

		const newHeight = Math.ceil(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(Foundation.Box.GetDimensions(activeSubmenu) as any).height
		)
		const parent = menu.parent('.is-drilldown')
		parent.height(newHeight)
	}

	$('#mainMenu').on('open.zf.drilldown', function (event, submenu) {
		const $submenu = $(submenu)
		const title = $submenu.children('a').text()
		const $backButton = $submenu.children('ul').children('.js-drilldown-back')
		$backButton.children('a').text(title)
		setTimeout(sizeMenu, 0)
	})

	$('#mainMenu').on('hide.zf.drilldown', function () {
		setTimeout(sizeMenu, 0)
	})

	function toggleMenu(event) {
		event.preventDefault()

		let overlay = $('.menu-overlay')
		if (overlay.length === 0) {
			overlay = $('<div class="menu-overlay"></div>')
			overlay.appendTo($('body'))
			overlay.on('click', toggleMenu)
		}

		// The design includes slightly different navigation hierarchy on mobile;
		// instead of having primary navigation and its menus independent of the
		// secondary menus, the mobile navigation drawer combines them and
		// differentiates only on typography.
		const clonedItems = $('#mainMenu .secondary-menu-item')
		if (clonedItems.length === 0) {
			$('.secondary-menu li').clone().addClass('secondary-menu-item').prependTo('#mainMenu')

			sizeMenu()
		}

		$('body').toggleClass('mobile-nav-open')
	}

	$('.toggle-menu').on('click', toggleMenu)

	$('.read-more-link').on('click', function (event) {
		event.preventDefault()
		$('.news').toggleClass('expanded')
		$('.read-more-link a').html($('.news').hasClass('expanded') ? 'See Less&hellip;' : 'See More&hellip;')
		new Foundation.Equalizer($('#news')).applyHeight([])
	})

	$('.filter-box-button').on('click', function (event) {
		event.preventDefault()
		$(this).toggleClass('is-active')
	})

	$('input, select').each(function () {
		const $this = $(this)
		$this.on('blur', function () {
			$this.addClass('has-been-focused')
		})
	})

	$('select.select2').select2()

	$('thead th.numeric').data('type', 'num')

	$('.of-subform input[type=checkbox].of-subform-delete')
		.wrap('<label class="hollow button of-subform-delete-button"></label>')
		.after(function () {
			return '<span class="of-subform-delete-button-text">' + $(this).data('ofDeleteButtonString') + '</span>'
		})
		.addClass('hidden-off-screen')
		.click(function () {
			const $checkbox = $(this)

			$checkbox.closest('.of-subform').toggleClass('deleting')

			const $text = $checkbox.siblings('.of-subform-delete-button-text')

			if ($checkbox.is(':checked')) {
				$text.text($checkbox.data('ofUndeleteButtonString'))
			} else {
				$text.text($checkbox.data('ofDeleteButtonString'))
			}
		})

	$('input[type=submit]').addClass('button')
	$('input[type=reset]').addClass('hollow button')
	;['checkbox', 'radio'].forEach(function (type) {
		$('[type="' + type + '"]:not(.hidden-off-screen)').each(function (index) {
			const $this = $(this)
			let parent = $this.parent()
			let label = null
			if (parent[0].tagName.toLowerCase() === 'label') {
				label = parent
				label.next('br').remove()
				parent = $('<div>')
				parent.insertBefore(label)
				parent.append($(this))
				parent.append(label)
				if ($this.attr('id') != null) {
					label.attr('for', $this.attr('id'))
				} else {
					const id = 'custom-' + type + '-' + index
					label.attr('for', id)
					$this.attr('id', id)
				}
			} else if (parent.children().length == 2) {
				// already in the right form
			} else if (((label = $this.next('label')), $this.attr('id') != null && label.attr('for') === $this.attr('id'))) {
				label.next('br').remove()
				parent = $('<div>')
				parent.insertBefore($this)
				parent.append($this)
				parent.append(label)
			} else {
				parent = null
			}
			if (parent != null) {
				parent.addClass('custom-' + type)
				$('<div class="custom-indicator">')
					.insertAfter($this)
					.on('click', () => {
						$this.trigger('focus')
						$this.trigger('click')
					})
			}
		})
	})

	for (let columns = 0; columns < 10; columns++) {
		const options = {
			scrollX: true,
			fixedHeader: true,
			fixedColumns: { leftColumns: columns },
			order: [],
			paging: false,
			searching: false,
			info: false,
		}
		if (Foundation.MediaQuery.atLeast('medium')) {
			delete options.fixedColumns
		} else {
			delete options.fixedHeader
		}
		const $table = $('table.data-freeze-' + columns)
		$table.attr('width', '100%')
		new DataTable($table, options)
		$table.addClass('datatable')
	}

	function convertButtonGroupToDropdown(groupElement) {
		const $buttonGroup = $(groupElement)
		const buttonGroupId = $buttonGroup.attr('id')

		const $wrapper = $('<div class="dropdown-wrapper"></div>')

		const labelElts = $buttonGroup.find('.label')
		if (labelElts.length == 1) {
			// more than one is undefined behavior
			const $labelElt = $(labelElts[0])

			const $label = $('<label class="label">' + $labelElt.html() + '</label>')
			if (buttonGroupId) {
				$label.attr('for', buttonGroupId)
			}

			$wrapper.append($label) // We don't just copy the labelElts because it may have button-specific styles, tooltips, etc.
		}

		const $dropdown = $<HTMLSelectElement>('<select class="tab-select" id="' + buttonGroupId + '"></select>')
		$dropdown.append(
			$buttonGroup
				.find('a')
				.map(function () {
					const $link = $(this)
					const $option = $('<option>').attr('value', $link.attr('href')).text($link.text())
					if ($link.hasClass('active')) {
						$option.prop('selected', true)
					}
					if ($link.hasClass('disabled')) {
						$option.prop('disabled', true)
					}
					return $option
				})
				.get()
		)
		$dropdown.on('change', function () {
			document.location.href = this.value
		})
		$wrapper.append($dropdown)

		$buttonGroup.replaceWith($wrapper)
	}
	$('.button-group:not(.no-collapse) .button:nth-child(8)')
		.parent()
		.each(function () {
			convertButtonGroupToDropdown(this)
		})
	$('.button-group.always-collapse').each(function () {
		convertButtonGroupToDropdown(this)
	})
	if (!Foundation.MediaQuery.atLeast('medium')) {
		$('.button-group').each(function () {
			convertButtonGroupToDropdown(this)
		})
	}

	$('.required').each(function () {
		const $this = $(this)
		const title = $this.attr('title')
		$this.addClass('stealth-tooltip')
		if (title) {
			$this.attr('title', title + ' (required)')
		} else {
			$this.attr('title', 'required')
		}
	})

	$('.optional').each(function () {
		const $this = $(this)
		const title = $this.attr('title')
		$this.addClass('stealth-tooltip')
		if (title) {
			$this.attr('title', title + ' (optional)')
		} else {
			$this.attr('title', 'optional')
		}
	})

	$('p.error, p.errors')
		.filter('.passback')
		.not('.suppress-changes-not-saved')
		.append(' (Your changes were not saved.)')
	$('ul.error, ul.errors')
		.filter('.passback')
		.not('.suppress-changes-not-saved')
		.before('<p class="error error-intro">Please correct these errors. (Your changes were not saved.)</p>')

	// Printing DataTables screws them up, especially with fixedHeader, so we get rid of the special DataTables features upon printing.
	// It would be nice for this not to be necessary, or to restore them after printing, or make it possible to print custom-sorted versions or similar, but I can't figure out how to do that at this point.
	window.onbeforeprint = function () {
		$('.datatable').each(function () {
			$(this).DataTable().destroy()
		})
	}

	// This is a bit of preprocessor magic, so we have to repeat the boilerplate on every line
	// and use a string literal as the first argument to `new URL()`.
	const preloadImages = [
		new URL('/images/checkbox-enabled-checked.png', import.meta.url),
		new URL('/images/checkbox-enabled-checked-focused.png', import.meta.url),
		new URL('/images/checkbox-enabled-unchecked.png', import.meta.url),
		new URL('/images/checkbox-enabled-unchecked-focused.png', import.meta.url),
		new URL('/images/radio-enabled-checked.png', import.meta.url),
		new URL('/images/radio-enabled-checked-focused.png', import.meta.url),
		new URL('/images/radio-enabled-unchecked.png', import.meta.url),
		new URL('/images/radio-enabled-unchecked-focused.png', import.meta.url),
		new URL('/images/table-sort-asc.png', import.meta.url),
		new URL('/images/table-sort-desc.png', import.meta.url),
	]
	preloadImages.forEach(function (url) {
		new Image().src = url.toString()
	})

	const modalsToOpen = $('.reveal.open-on-load')
	if (modalsToOpen.length > 0) {
		modalsToOpen.foundation('open')
	}
})

function disableAndSubmit(target: HTMLButtonElement) {
	target.disabled = true
	if (target.value) {
		target.value = 'Working\u2026'
	} else if (target.innerHTML) {
		target.innerHTML = 'Working&hellip;'
	}

	if (target.form && target.form.submit) {
		target.form.submit()
	}

	return true
}
window.disableAndSubmit = disableAndSubmit

export default {}
